
import {
  IonFooter,
  IonIcon,
  IonLabel,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/vue'
import { defineComponent, reactive, onUpdated, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { home, cart, cashOutline, personOutline, listOutline } from 'ionicons/icons'
import firebaseAuth from '@/hooks/firebase-auth'

export default defineComponent({
  name: 'AppFooter',
  components: {
    IonFooter,
    IonIcon, 
    IonLabel,
    IonToolbar,
    IonTabBar, 
    IonTabButton, 
    IonTabs
  },
  setup() {
    const footerState = reactive ({
      selectedBookId: null
    })

    const getSelectedBook = async () => {
      footerState.selectedBookId = await firebaseAuth().userData.value.settings.defaultBook
      // console.log(footerState.selectedBookId)
      return footerState.selectedBookId
    }

    onMounted ( async () => {
      await getSelectedBook()
    })

    onUpdated ( async () => {
      await getSelectedBook()
    })

    return {
      router: useRouter(),
      footerState,
      getSelectedBook,
      
      home,
      cart,
      cashOutline,
      listOutline,
      personOutline
    }
  }
})
