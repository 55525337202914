<template>
  <ion-footer>
    <ion-toolbar>
      <ion-tabs>      
        <!-- Tab bar -->
        <ion-tab-bar slot="bottom">
          <ion-tab-button tab="home" href="/home">
            <ion-icon :icon="home"></ion-icon>
            <ion-label>Dashboard</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="expenses" href="/expenses" :disabled="!footerState.selectedBookId">
            <ion-icon :icon="cart"></ion-icon>
            <ion-label>Expense</ion-label>
          </ion-tab-button>
          <ion-tab-button tab="income" href="/incomes" :disabled="!footerState.selectedBookId">
            <ion-icon :icon="cashOutline"></ion-icon>
            <ion-label>Income</ion-label>
          </ion-tab-button> 
          <ion-tab-button tab="reports" href="/reports" :disabled="!footerState.selectedBookId">
            <ion-icon :icon="listOutline"></ion-icon>
            <ion-label>Reports</ion-label>
          </ion-tab-button>          
          <ion-tab-button tab="account" href="/account">
            <ion-icon :icon="personOutline"></ion-icon>
            <ion-label>Account</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import {
  IonFooter,
  IonIcon,
  IonLabel,
  IonToolbar,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/vue'
import { defineComponent, reactive, onUpdated, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { home, cart, cashOutline, personOutline, listOutline } from 'ionicons/icons'
import firebaseAuth from '@/hooks/firebase-auth'

export default defineComponent({
  name: 'AppFooter',
  components: {
    IonFooter,
    IonIcon, 
    IonLabel,
    IonToolbar,
    IonTabBar, 
    IonTabButton, 
    IonTabs
  },
  setup() {
    const footerState = reactive ({
      selectedBookId: null
    })

    const getSelectedBook = async () => {
      footerState.selectedBookId = await firebaseAuth().userData.value.settings.defaultBook
      // console.log(footerState.selectedBookId)
      return footerState.selectedBookId
    }

    onMounted ( async () => {
      await getSelectedBook()
    })

    onUpdated ( async () => {
      await getSelectedBook()
    })

    return {
      router: useRouter(),
      footerState,
      getSelectedBook,
      
      home,
      cart,
      cashOutline,
      listOutline,
      personOutline
    }
  }
})
</script>