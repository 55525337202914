
import { 
  IonButton,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  alertController
} from '@ionic/vue'
import { defineComponent } from 'vue'
import { logOutOutline } from 'ionicons/icons'
import { useRouter } from 'vue-router'
import useFirebaseAuth from '../hooks/firebase-auth'

export default defineComponent({
  name: 'AppHeader',
  components: {
    IonButton,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
  },

  setup() {
    const { logout } = useFirebaseAuth()
    const router = useRouter()
    const doLogout = async () => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Confirm!',
          message: 'Do You Want to Logout?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                // console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'Okay',
              handler: async () => {
                await logout()
                localStorage.removeItem('selectedBook')
                router.replace({path:"/login"})
              },
            },
          ],
        });
      return alert.present()      
    }
    
    return {
      logOutOutline,
      logout,
      doLogout
    }
  }
})
